import React, { Component } from 'react';
import { Progress as ImportProgress } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Progress extends Component {
  render() {
    return (
      <ImportProgress {...excludeKeys(this.props)} />
    );
  }
}
