import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as ImportDropdown } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Dropdown extends Component {

  mapArray(option, index) {
    return {
      key: index,
      value: option[this.props.mapBy] || option.value,
      text: option[this.props.mapBy] || option.text,
    };
  }

  mapObject(object) {
    return Object.keys(object).map((option, index) => {
      return {
        key: index,
        value: object[option],
        text: object[option],
      };
    });
  }


  render() {
    let formattedArray;

    // options are provided as a prop, and it is an object, but not an array
    if (this.props.options && !Array.isArray(this.props.options) && typeof this.props.options === 'object') {
      formattedArray = this.mapObject(this.props.options);
    } else if (this.props.options && Array.isArray(this.props.options)) {
      formattedArray = this.props.options.map(this.mapArray.bind(this));
    } else {
      formattedArray = [];
    }

    if (this.props.options) {
      return <ImportDropdown {...excludeKeys(this.props)} options={formattedArray} />;
    }

    return (
      <ImportDropdown {...excludeKeys(this.props)}>
        {this.props.children}
      </ImportDropdown>
    );
  }
}

export class DropdownDivider extends Component {
  render() {
    return (
      <ImportDropdown.Divider {...excludeKeys(this.props)} />
    );
  }
}

export class DropdownHeader extends Component {
  render() {
    return (
      <ImportDropdown.Header {...excludeKeys(this.props)}>
        {this.props.children}
      </ImportDropdown.Header>
    );
  }
}

export class DropdownItem extends Component {
  render() {
    return (
      <ImportDropdown.Item {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportDropdown.Item>
    );
  }
}

export class DropdownMenu extends Component {
  render() {
    return (
      <ImportDropdown.Menu {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportDropdown.Menu>
    );
  }
}

Dropdown.propTypes = {
  mapBy: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.array,
};


// DropdownHeader.propTypes = {
//   children: PropTypes.node,         // DropdownHeader has flexible content
// };

// DropdownItem.propTypes = {
//   children: PropTypes.node,         // DropdownItem has flexible content
// };

// DropdownMenu.propTypes = {
//   children: PropTypes.node,         // DropdownMenu has flexible content
// };
