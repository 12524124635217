import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn, GridRow } from './collections/Grid';
import { Header } from '../containers/elements/Header';
import { Button } from './elements/Button';
import { StepGroup, Step, StepTitle, StepContent, StepDescription } from './elements/Step';
import { Icon } from './elements/Icon';


class Wizard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
    };
  }

  componentWillMount() {
    if (!isNaN(this.props.activeStep)) {
      this.setState({
        activeStep: this.props.activeStep,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isNaN(nextProps.activeStep)) {
      this.setState({
        activeStep: nextProps.activeStep,
      });
    }
  }

  getPreviousButton() {
    return this.props.previousButton || <Button>Prev</Button>;
  }

  getNextButton() {
    return this.props.nextButton || <Button>Next</Button>;
  }

  getFinishButton() {
    return this.props.finishButton || <Button>Finish</Button>;
  }

  isFirstStep() {
    return this.state.activeStep === 0;
  }

  isLastStep() {
    return this.state.activeStep === (this.props.children.length - 1);
  }

  isExitAllowed(isForward) {
    const fx = this.props.children[this.state.activeStep].props.allowExit;
    return !fx || fx(isForward);
  }

  isAutonomous() {
    return isNaN(this.props.activeStep);
  }

  canGoPrevious() {
    return !this.isFirstStep() && this.isExitAllowed(false);
  }

  canGoNext() {
    return !this.isLastStep() && this.isExitAllowed(true);
  }

  canFinish() {
    return this.isLastStep() && this.isExitAllowed(true);
  }

  goPrevious() {
    if (!this.isAutonomous()) {
      return this.props.onStepChange(this.state.activeStep, this.state.activeStep - 1, this.props.children.length);
    }

    if (!this.isFirstStep() && this.isExitAllowed(false)) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  }

  goNext() {
    if (this.canFinish()) {
      return this.props.onFinish();
    }

    if (!this.isAutonomous()) {
      return this.props.onStepChange(this.state.activeStep, this.state.activeStep + 1, this.props.children.length);
    }

    if (!this.isLastStep() && this.isExitAllowed(true)) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  renderStep(stepIndex, stepLength) {
    return React.cloneElement(this.props.children[stepIndex], {
      stepIndex,
      stepLength,
      stepFirst: stepIndex === 0,
      isLast: stepIndex === stepLength - 1,
    });
  }

  renderActiveStep() {
    return this.renderStep(this.state.activeStep, this.props.children.length);
  }

  renderControls() {
    const pcActive = React.cloneElement(this.getPreviousButton(), { onClick: () => this.goPrevious() });
    const ncActive = React.cloneElement(this.isLastStep() ? this.getFinishButton() : this.getNextButton(), { onClick: () => this.goNext() });

    return (
      <Grid>
        <GridRow>
          <GridColumn width={8}>
            <div style={{ textAlign: 'left' }}>
              {this.canGoPrevious() ? pcActive : null}
            </div>
          </GridColumn>
          <GridColumn width={8} style={{ textAlign: 'right' }}>
            {this.canGoNext() || this.canFinish() ? ncActive : null }
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }

  renderStepFlow() {
    const { children } = this.props;
    const onClickHandler = index => (index < this.state.activeStep ? () => {
      this.props.onStepChange(null, index);
    } : null); // Clickhandler disables going forward but enables going back when clicking any steps prior

    return (
      <div
        style={{
          textAlign: 'center',
          position: 'sticky',
          top: 108,
          backgroundColor: '#EEE',
          boxShadow: '0 2px 6px 0 #AAA, inset 0 -4px 0 0 #DDD',
          borderRadius: 4,
          zIndex: 99
        }}
      >
        <StepGroup>
          {children.map((step, index) =>
            <Step key={step.props.title} active={index === this.state.activeStep} onClick={onClickHandler(index)}>
              {step.props.icon ? <Icon name={step.props.icon} /> : null}
              <StepContent>
                {step.props.title ? <StepTitle>{step.props.title}</StepTitle> : null}
                {step.props.description ? <StepDescription>{step.props.description}</StepDescription> : null}
              </StepContent>
            </Step>
          )}
        </StepGroup>
      </div>
    );
  }

  render() {
    const { hideControls, hideFlow, header } = this.props;

    return (
      <div>
        {!hideFlow ? this.renderStepFlow() : null}
        {!!header && <Header as="h3">{header}</Header>}
        {this.renderActiveStep()}
        {!hideControls ? this.renderControls() : null}
      </div>
    );
  }

}

Wizard.propTypes = {
  header: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),  // WizardStep instances
  previousButton: PropTypes.node,               // Override the previous button appearance
  nextButton: PropTypes.node,                   // Override the next button appearance
  finishButton: PropTypes.node,                 // Override the finish button appearance

  activeStep: PropTypes.number,                 // The active step. Supply only if controlled from outside
  hideControls: PropTypes.bool,                 // Hide the previous / next / finish controls
  hideFlow: PropTypes.bool,                     // Hide the flow indicator in the top

  onStepChange: PropTypes.func,                 // Triggered when the step changes, but only when activeStep is controlled
  onFinish: PropTypes.func,                     // Triggered when the finish button is pressed
};

Wizard.defaultProps = {
  hideControls: false,
  hideFlow: false,
};

export default Wizard;
