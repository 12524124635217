import axios from 'axios';
import config from 'config';

const createRequestHandler = () => {
	// const headers = {};

	// const externalRequest = axios.create({
	// 	validateStatus: status => status >= 200 && status < 300
	// });

	const handledRequest = axios.create({
		baseURL: config.coreDomain,
		validateStatus: status => status >= 200 && status < 300,
		withCredentials: true
		// headers
	});

	// Reduce response to something easier to work with
	handledRequest.interceptors.response.use((response) => {
		if (!(response && response.data && typeof response.data.result !== 'undefined')) {
			throw `Developer error. The response should always have a data object with a result attached.`;
		}

		return response.data.result; // Return only the result in happy path case (status code 2xx)
	}, (err) => {
		return Promise.reject(err.response.data); // Return the full data payload (as opposed to just the result) in case of errors
	});

	return handledRequest;
};

const requests = request => ({
	system: {
		getSystemSettings: () => request.get('/system'),
		restartServer: () => request.get('restart'),
		getLogs: type => request.get(`logs/${type}`),
		getCoupons: () => request.get('/system/coupons')
	},
	users: {
		getUsers: () => request.get('/users'),
		logout: () => request.get('/users/logout'),
		inviteUser: ({ email }) => request.post('/users/invite', {
			email,
			roles: []
		}),
		editUser: ({ userId }, newUser) => request.put(`/users/${userId}`, {
			newUser
		}),
		deleteUser: ({ userId }) => request.delete(`/users/${userId}`)
	},
	sites: {
		getSites: () => request.get('/sites')
	}
});

export default requests(createRequestHandler());
