import React, { Component } from 'react';

class AppContentSection extends Component {
	render() {
		return (
			<div
				className={this.props.className ? `app-content-section ${this.props.className}` : 'app-content-section'}
				style={this.props.style}
			>
				{ this.props.children }
			</div>
		);
	}
}

AppContentSection.defaultProps = {
	children: <p>No content...</p>,
};

export default AppContentSection;
