import React, { Component } from 'react';
import { Reveal as ImportReveal } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Reveal extends Component {
  render() {
    return (
      <ImportReveal {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportReveal>
    );
  }
}

export class RevealContent extends Component {
  render() {
    return (
      <ImportReveal.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportReveal.Content>
    );
  }
}
