import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from './elements/Input';
import { Header, HeaderContent, HeaderSubheader } from '../containers/elements/Header';
import { Icon } from './elements/Icon';
import { Message } from './collections/Message';
import { Label } from '../components/elements/Label';

class ScrollBox extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			options: props.options,
		}

		this.search = this.search.bind(this);
		this.renderListItems = this.renderListItems.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.options !== this.props.options) {
			this.setState({
				options: nextProps.options
			});
		}
	}

	search(e, data) {
		const { options, mapBy } = this.props;
		const list = options.filter(el => {
			const str = el[mapBy].toLowerCase();
			return str.includes(data.value.toLowerCase())
		});

		this.setState({ options: list });
	}

	renderListItems() {
		const { options } = this.state;
		const { name, title, activeItem, height, isActive, mapBy, onChange, noResultsMessage } = this.props;

		if (options.length === 0) return (
			<Message size="mini" className="info">
				<Icon name="sn-search3" style={{ marginRight: 8 }} />
				<span>{noResultsMessage || 'No results...'}</span>
			</Message>
		);

		return options.map((option, index) => {
			return (
				<li
					onClick={(e,d) => onChange(e, option/*{ value: e.target.innerHTML, key: e.target.value }*/)}
					className={isActive ? 'active-item' : null}
					key={index}>{mapBy ? option[mapBy] : option}
				</li>
			);
		});
	}

	render() {
		const { options } = this.state;
		const { icon, title, activeItem, height } = this.props;
		return (
			<div>
				<Header className="scroll-box-header">
					{icon ? <Icon name={icon} className="scrollbox-icon" /> : null}
					<HeaderContent>
						{title} 
						<div>
							<Label basic circular size="small" className="info" style={{ marginLeft: 0 }}>{activeItem}</Label>
						</div>
					</HeaderContent>
				</Header>
				<Input fluid icon="search" size="mini" placeholder="Search fonts" style={{ marginBottom: 4 }} onChange={this.search} />
				<ul className="scroll-box" style={{ maxHeight: height > 1 ? height : 128 }}>
					{this.renderListItems()}
				</ul>
			</div>
		);
	}
}

ScrollBox.propTypes = {
	options: PropTypes.array.isRequired,
	mapBy: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default ScrollBox;

							// here is how to apply inline font-family
							/*const style = document.createElement('style');
							style.type = 'text/css';
							style.innerHTML = `@font-face { font-family: ${option.family}; src: url(${option.files.regular});  }`;
							document.getElementsByTagName('head')[0].appendChild(style);*/


