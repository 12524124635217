import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class HoverDropZone extends Component {

  render() {
    switch (this.props.position) {
    case 'top': {
      return (
        <div className="target-zone-top" />
      );
    }

    case 'bottom': {
      return (
        <div className="target-zone-bottom" />
      );
    }

    case 'left': {
      return (
        <div className="target-zone-left" />
      );
    }

    case 'right': {
      return (
        <div className="target-zone-right" />
      );
    }

    default: {
      return (
        <div className="drop-zone" />
      );
    }
    }
  }
}

HoverDropZone.propTypes = {
  position: PropTypes.string.isRequired,
};
