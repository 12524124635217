import React, { Component } from 'react';
import { Flag as ImportFlag } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Flag extends Component {
  render() {
    return (
      <ImportFlag {...excludeKeys(this.props)} />
    );
  }
}
