import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardGroup } from './views/Card';
import { Popup } from './modules/Popup';
import { Segment } from './elements/Segment';
import { Icon } from './elements/Icon';

class Picker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: this.props.options[0],
    };
  }

  updateElement(e, option) {
    this.setState({ selectedValue: option })
    this.props.onChange(e, option);
  }

  renderContent() {
    switch (this.props.type) {
    case 'icon':
      return (
        <CardGroup itemsPerRow={this.props.itemsPerRow || 4}>
          {this.props.options.map(option => (
            <Card onClick={e => this.updateElement(e, option)}>
              <Icon name={option} />
            </Card>
          ))}
        </CardGroup>
      );
    
    case 'color':
      return (
        <CardGroup itemsPerRow={this.props.itemsPerRow || 4}>
          {this.props.options.map(option => (
            <Card onClick={e => this.updateElement(e, option)} style={{ width: 50, height: 50, backgroundColor: option }} />
          ))}
        </CardGroup>
      );
    }
  }

  renderTrigger() {
    switch (this.props.type) {
    case 'icon':
      return (
        <Segment circular style={{ width: 50, height: 50 }}>
          <Icon name={this.state.selectedValue} />
        </Segment>
      );
    
    case 'color':
      return (
        <Segment circular style={{ width: 50, height: 50, backgroundColor: this.state.selectedValue }} />
      );
    }
  }

  render() {
    return (
      <Popup
        trigger={this.renderTrigger()}
        content={this.renderContent()}
        on="click"
        hideOnScroll
        position="top center"
        className="svift-picker"
      />
    );
  }
}


Picker.propTypes = {
  options: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default Picker;

