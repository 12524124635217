import React, { Component } from 'react';
import excludeKeys from '../../../utils/excludeKeys';

export default function(ComposedComponent) {
  return class field extends Component {
    render() {
      return (
        <ComposedComponent {...excludeKeys(this.props)} />
      );
    }
  };
}
