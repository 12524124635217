import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from './elements/Container';

class Box extends Component {
	render() {
		const { text, textAlign, style, children, name } = this.props;

		return (
			<div className="box-container" style={style}>
				<Container 
					text={text ? true : null}
					textAlign={textAlign ? textAlign : 'right'}
					className="box w-icon"
					style={style}
				>
					{children}
				</Container>
			</div>
		);
	}
}

Box.propTypes = {
	textAlign: PropTypes.string,
	text: PropTypes.bool,
	children: PropTypes.node,
};

Box.defaultProps = {
	text: false,
	textAlign: 'center',
	children: <p>No content...</p>,
};

export default Box;
