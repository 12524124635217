import React, { Component } from 'react';
import PropTypes from 'prop-types';
import excludeKeys from '../../../utils/excludeKeys';

class UserNav extends Component {
  render() {
    return (
      <div id="usernav" className="sidebar">
        <div id="usernav-content" className="sidebar-content right-sidebar" {...excludeKeys(this.props)}>
          { this.props.children }
        </div>
      </div>
    );
  }
}

UserNav.propTypes = {
  children: PropTypes.node,         // UserNav has flexible content
};

UserNav.defaultProps = {
  children: <p>No content...</p>,
};

export default UserNav;
