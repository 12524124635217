const createConfig = (deploymentType = 'development') => {
	const config = {
		protocol: 'http',
		domain: 'http://dev.system.svift.net:3000',
		adminDomain: 'http://dev.admin.svift.net:3000',
		coreDomain: 'http://localhost:3000'
	};

	if (deploymentType === 'production') {
		config.protocol = 'https';
		config.domain = 'https://system.svift.net';
		config.adminDomain = 'https://admin.svift.net';
		config.coreDomain = 'https://core.svift.net';
	}

	if (deploymentType === 'sandbox') {
		config.protocol = 'https';
		config.domain = 'https://test.system.svift.net';
		config.adminDomain = 'https://test.admin.svift.net';
		config.coreDomain = 'https://test.core.svift.net';
	}

	return config;
};

export default createConfig(process.env.NODE_ENV);