const excludeKeys = (props, arrayOfExcludedProps) => {
	const newProps = { ...props };
	if (arrayOfExcludedProps && arrayOfExcludedProps.length > 0) {
		arrayOfExcludedProps.map((excludedProp) => {
			delete newProps[excludedProp];
		});
	}

	return newProps;
};

export default excludeKeys;
