import 'rc-slider/assets/index.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider, { Handle } from 'rc-slider';
import { Popup, Input, Label } from 'semantic-ui-react';

export default class NumberPicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }



  render() {
    const { min, max, unit, fluid, value, onChange, decimals } = this.props;
    const formattedValue = decimals ? value * 100 : value;

    const handle = props => <Handle className={props.className} offset={props.offset} />;
    const Trigger = (
      <Input fluid={fluid} type="number" value={value}>
        <input />{ unit ? <Label content={unit} /> : null }
      </Input>
    );

    const Content = (
      <Slider
        min={min}
        max={max}
        value={formattedValue}
        onChange={data => {
          console.log('DATA VALUE IN UI: ', data);
          onChange(data);
        }}
        handle={handle}
        style={{ width: 300 }}
      />
    );

    return (
      <Popup
        trigger={Trigger}
        content={Content}
        on='click'
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position='top center'
      />
    );
  }
}

NumberPicker.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  unit: PropTypes.string,
  fluid: PropTypes.bool,
};
