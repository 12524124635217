import React, { Component } from 'react';
import { Popup as ImportPopup } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Popup extends Component {
  render() {
    return (
      <ImportPopup {...excludeKeys(this.props, ['positioning'])}>
        { this.props.children }
      </ImportPopup>
    );
  }
}

export class PopupHeader extends Component {
  render() {
    return (
      <ImportPopup.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportPopup.Header>
    );
  }
}

export class PopupContent extends Component {
  render() {
    return (
      <ImportPopup.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportPopup.Content>
    );
  }
}
