import constants from './constants';

const getDefaultState = () => {
	return {
		sections: [],
		currentSection: null,
		selectSection: null,
	};
};

const initialState = getDefaultState();

export default (state = initialState, action) => {
	switch (action.type) {
		case constants.SET_SECTIONS:
			return {
				...state,
				sections: action.data.sections,
				currentSection: action.data.currentSection,
				selectSection: null
			};

		case constants.SELECT_SECTION:
			return {
				...state,
				selectSection: action.data || null
			};

		case constants.SET_CURRENT_SECTION:
			return {
				...state,
				currentSection: action.data || null,
				selectSection: null
			};

		default:
			return state;
	}
};