import React, { Component } from 'react';
import { Label as ImportLabel } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Label extends Component {
  render() {
    return (
      <ImportLabel {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportLabel>
    );
  }
}

export class LabelDetail extends Component {
  render() {
    return (
      <ImportLabel.Detail {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportLabel.Detail>
    );
  }
}

export class LabelGroup extends Component {
  render() {
    return (
      <ImportLabel.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportLabel.Group>
    );
  }
}
