import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IntroductionIndicator extends Component {
	render() {
		const { id, className, style } = this.props;

		return (
			<div
				id={id}
				className="sn-pulse-spinner"
				// className={className ? `sn-pulse-spinner ${className}` : 'sn-pulse-spinner'}
				// style={Object.assign(style, pulseStyle && pulseStyle)}
				style={style}
			>
				<div className={className ? `sn-double-bounce1 ${className}` : 'sn-double-bounce1'}></div>
				<div className={className ? `sn-double-bounce2 ${className}` : 'sn-double-bounce2'}></div>
			</div>
		);
	}
}

IntroductionIndicator.propTypes = {
	// children: PropTypes.node,
	id: PropTypes.string,
	className: PropTypes.string,
	// style: PropTypes.node,
};

export default IntroductionIndicator;
