import React, { Component } from 'react';
import { Checkbox as ImportCheckbox } from 'semantic-ui-react';

export class Checkbox extends Component {
	render() {
		return (
			<div>
				<ImportCheckbox
					{...this.props}
					onChange={(e, d) => this.props.onChange(e, d.name, d.value, this.props)}
				/>
			</div>
		);
	}
}
