import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Sites from 'containers/content/sites/Sites';
import Superadmin from 'containers/content/superadmin/Superadmin';
import Users from 'containers/content/users/Users';
import api from 'api';

const mapStateToProps = (state) => {
	return {
		activeContent: state.data.activeContent,
		roles: state.data.user.roles
	};
};

class Content extends PureComponent {
	renderContent() {
		switch (this.props.activeContent) {
			case 'sites':
				return <Sites />;
			case 'users':
				return <Users />;
			case 'superadmin':
				if (this.props.roles.findIndex(({ scope, type }) => scope === '*' && type === 'superadmin') !== -1) {
					return (
						<Superadmin />
					);
				}

				return (
					<div> Restricted </div>
				);
			default:
				return <div>Nothing here yet</div>;
		}
	}

	render() {
		return this.renderContent();
	}
}

export default connect(mapStateToProps)(Content);