import React, { Component } from 'react';
import { TextArea as ImportTextArea } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

class TextArea extends Component {
  render() {
    return (
        <ImportTextArea {...excludeKeys(this.props)} onChange={(e, d) => this.props.onChange(e, d, this.props)} />
    );
  }
}

export default TextArea;
