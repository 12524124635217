import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { Router, browserHistory } from 'react-router'; Look at connecteed-react-router; routes in store = no more passing down params constantly = yes please
import store from 'store/store';
import config from 'config';

import { AppContainer, AppFooter, AppContent } from 'svift-ui';
import Header from 'containers/header/Header';
import Content from 'containers/content/Content';
import api from 'api';
import constants from 'store/constants';

class Root extends PureComponent {
	render() {
		return (
			<Provider store={store}>
				<AppContainer>
					<Header />

					<AppContent>
						<Content />
						<AppFooter />
					</AppContent>
				</AppContainer>
			</Provider>
		);
	}
}

api.system.getSystemSettings().then(({ systemSettings, user }) => {
	const adminUrl = `${config.adminDomain}`;

	if (!user) {
		window.location.replace(adminUrl);

		return;
	}

	const isAdmin = user.roles.some(({ type }) => type === 'admin' || type === 'superadmin');

	if (!isAdmin) {
		window.location.replace(adminUrl);

		return;
	}

	store.dispatch({
		type: constants.SET_SYSTEM_SETTINGS,
		data: {
			user,
			systemSettings
		}
	});

	ReactDOM.render(<Root />, document.getElementById('root'));
});

// api.getSystemSettings().then(({ systemSettings, user }) => {
// 	// Prepare translations takes the raw translations from the system and converts them to a usable format
// 	setupTranslations(systemSettings);

// 	store.dispatch({
// 		type: 'SET_SYSTEM_SETTINGS',
// 		data: systemSettings
// 	});

// 	store.dispatch(userActions.setUser(user));

// 	// If reducers need to react to the route on mount, they can listen to SETUP and do pre-mount adjustments to the state
// 	setupRouteState();

// 	render(
// 		<Provider store={store}>
// 			<Routes />
// 		</Provider>,
// 		document.getElementById('root')
// 	);
// }).catch(console.log.bind('Error - couldnt get system settings: '));


// ReactDOM.render(<Root />, document.getElementById('root'));