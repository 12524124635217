import React, { Component } from 'react';
import { Form as ImportForm } from 'semantic-ui-react';
import excludeKeys from '../../../../../utils/excludeKeys';

class FormRadio extends Component {
  render() {
    return (
      <ImportForm.Radio {...excludeKeys(this.props)} />
    );
  }
}

export default FormRadio;
