import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from './Icon';

export default class BuildingBlock extends Component {
	constructor() {
		super();

		this.state = {
			dragged: false,
		};

		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragStart() {
		this.setState({
			dragged: true,
		});
	}

	onDragEnd() {
		this.setState({
			dragged: false,
		});
	}

	render() {
		let className = 'building-block ' + this.props.className;
		let draggable = true;

		if (this.props.disabled) {
			className += ' disabled';
			draggable = false;
		} else if (this.state.dragged || this.props.dragged) {
			className += ' dragged';
			draggable = false;
		}

		return (
			<div className={className} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} draggable={draggable}>
				<Icon name={this.props.icon} />
				<p>{this.props.title}</p>
			</div>
		);
	}
}

BuildingBlock.propTypes = {
	dragged: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	title: PropTypes.string,
};
