import constants from 'store/constants';

/*
	Just using one main reducer for now; no reason to do any overkill solutions for superadmin
*/
const getDefaultState = () => ({
	activeContent: 'sites',
	systemSettings: {},
	user: {},
	users: [],
	sites: []
});

const reducer = (state = getDefaultState(), action) => {
	switch (action.type) {
		case constants.SET_ACTIVE_CONTENT: {
			return {
				...state,
				activeContent: action.data
			};
		}

		case constants.SET_SYSTEM_SETTINGS: {
			return {
				...state,
				user: action.data.user,
				systemSettings: action.data.systemSettings
			};
		}

		case constants.SET_USERS: {
			return {
				...state,
				users: action.data
			};
		}

		case constants.SET_SITES: {
			return {
				...state,
				sites: action.data
			};
		}

		default:
			return state;
	}
};

export default reducer;