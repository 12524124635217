import React, { Component } from 'react';
import excludeKeys from '../../../../utils/excludeKeys';
import radium from 'radium';

// const EnhancedLink = radium(ImportButton);

class CustomLink extends Component {
	render() {
		return (
			<a {...excludeKeys(this.props)}>
				{this.props.children}
			</a>
		);
	}
}

const Link = radium(CustomLink);

export default Link;

// export class ButtonContent extends Component {
//   render() {
//     return (
//       <ImportButton.Content {...excludeKeys(this.props)}>
//         { this.props.children }
//       </ImportButton.Content>
//     );
//   }
// }

// export class ButtonGroup extends Component {
//   render() {
//     return (
//       <ImportButton.Group {...excludeKeys(this.props)}>
//         { this.props.children }
//       </ImportButton.Group>
//     );
//   }
// }

// export class ButtonOr extends Component {
//   render() {
//     return (
//       <ImportButton.Or {...excludeKeys(this.props)} />
//     );
//   }
// }
