import React, { Component } from 'react';
import { Form as ImportForm } from 'semantic-ui-react';
import excludeKeys from '../../../../../utils/excludeKeys';

class FormDropdown extends Component {
  render() {
    return (
      <ImportForm.Dropdown {...excludeKeys(this.props)} />
    );
  }
}

export default FormDropdown;
