import React, { Component } from 'react';
import { Segment as ImportSegment } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Segment extends Component {
  render() {
    return (
      <ImportSegment {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportSegment>
    );
  }
}

export class SegmentGroup extends Component {
  render() {
    return (
      <ImportSegment.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportSegment.Group>
    );
  }
}
