import React, { PureComponent } from 'react';
import CouponDropdown from './CouponDropdown';
import {
	Modal,
	Button,
	ModalHeader,
	Form,
	Dimmer,
	Grid,
	GridColumn,
	Message,
	FormInput,
	Icon,
	Divider,
	Checkbox,
	Menu,
	SegmentGroup,
	Segment,
	Label,
	Header,
	Dropdown,
	MenuItem,
	Loader
} from 'svift-ui';

class EditUser extends PureComponent {
	constructor(props) {
		super(props);


		/* Add more granularity and move to system settings later */
		this.discountTypes = [
			'coupons',
			/*'activations',
			'addons'*/
		];

		/* Get sites list */
		this.permissions = {
			system: [
				'admin',
				'supporter'
			],
			/*
				Sites should be fetched dynamically later
			*/
		/*	'sites/123': [
				'designer',
				'writer',
				'admin'
			]*/
		};

		this.state = {
			user: {
				coupons: {},
				isBetaTester: false,
				isSuspended: false,
				...props.selectedUser.details /* if data is found in .details then the user hasn't been activated yet */ || props.selectedUser
			},
			selectedDiscountType: null,
			coupon: null,
			'discount%': '',
			selectedPermissionArea: null,
			role: null,
			loading: false
		};
	}

	renderDiscountInput() {
		return (
			<Menu size="mini">
				<Dropdown
					item
					placeholder="Discount type"
					options={this.discountTypes.map(discountType => {
						return {
							text: discountType,
							value: discountType
						};
					})}
					value={this.state.selectedDiscountType}
					onChange={(e, { value }) => {
						this.setState({
							selectedDiscountType: value
						});
					}}
				/>

				<MenuItem>
					{this.state.selectedDiscountType === 'coupons' ?
						<CouponDropdown
							selectedCoupon={this.state.coupon}
							selectCoupon={coupon => {
								this.setState({
									coupon
								});
							}}
						/>
						:
						<FormInput
							disabled={!this.state.selectedDiscountType}
							number
							placeholder="Amount %"
							icon="sn-star-full"
							value={this.state['discount%']}
							onChange={(e, { value }) => {
								this.setState({
									'discount%': value
								});
							}}
						/>
					}
				</MenuItem>

				<MenuItem position="right">
					<Button
						disabled={!this.state.selectedDiscountType || !this.state['discount%'] && !this.state.coupon}
						onClick={() => {
							this.setState({
								user: {
									...this.state.user,
									coupons: {
										...this.state.user.coupons,
										customerDiscount: this.state.coupon
									}
									/*discounts: {
										...this.state.user.discounts,
										[this.state.selectedDiscountType]: this.state.selectedDiscountType === 'coupon' ? : this.state['discount%']
									}*/
								}
							});
						}}
						size="mini"
						className="positive"
					>
						<Icon name="plus" /> Add discount
					</Button>
				</MenuItem>
			</Menu>
		);
	}

	renderDiscounts() {
		const coupons = this.state.user.coupons;

		return (
			<SegmentGroup size="mini">
				{Object.keys(coupons).map(couponKey => {
					return (
						<Segment key={couponKey}>
							<Label basic color="green">
								<Icon name="sn-subscription-base" />{couponKey}</Label>
								<Label basic><Icon name="sn-star-full" />{coupons[couponKey]}% coupon</Label>
								<Button
									size="mini"
									icon="sn-bin"
									floated="right"
									className="warning"
									onClick={() => {
										const newUser = {
											...this.state.user,
											coupons: {
												...this.state.user.coupons
											}
										};

										delete newUser.coupons[couponKey];

										this.setState({
											user: newUser
										});
									}}
								/>
						</Segment>
					);
				})}

				{/*<Segment><Label basic color="purple"><Icon name="sn-subscription-base" />Light activations</Label> <Label basic><Icon name="sn-star-full" />5 % discount</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>
				<Segment><Label basic color="orange"><Icon name="sn-subscription-frame" />Shop addons</Label> <Label basic><Icon name="sn-star-full" />7.5 % discount</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>
				<Segment><Label basic color="teal"><Icon name="sn-subscription-frame" />Booking addons</Label> <Label basic><Icon name="sn-star-full" />2.5 % discount</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>*/}
			</SegmentGroup>
		);
	}

	renderPermissionInput() {
		return (
			<Menu size="mini">
				<Dropdown
					item
					placeholder="Permission area"
					options={Object.keys(this.permissions).map(permissionArea => {
						return {
							text: permissionArea,
							value: permissionArea
						};
					})}
					value={this.state.selectedPermissionArea}
					onChange={(e, { value }) => {
						this.setState({
							selectedPermissionArea: value
						});
					}}
				/>

				<Dropdown
					item
					placeholder="User role"
					options={(this.permissions[this.state.selectedPermissionArea] || []).map(role => {
						return {
							text: role,
							value: role
						};
					})}
					value={this.state.role}
					onChange={(e, { value }) => {
						this.setState({
							role: value
						});
					}}
				/>

				<MenuItem position="right">
					<Button
						disabled={!this.state.selectedPermissionArea || !this.state.role}
						onClick={() => {
							const roleScopeCombinationExists = this.state.user.roles.some(({ type, scope }) => type === this.state.role && scope === this.state.selectedPermissionArea);

							if (roleScopeCombinationExists) return;

							this.setState({
								user: {
									...this.state.user,
									roles: this.state.user.roles.concat({
										type: this.state.role,
										scope: this.state.selectedPermissionArea
									})
								}
							});
						}}
						size="mini"
						className="positive"
					>
						<Icon name="plus" /> Add permission
					</Button>
				</MenuItem>
			</Menu>
		);
	}

	renderPermissions() {
		const { roles } = this.state.user;

		return (
			<SegmentGroup size="mini">
				{roles.map(({ type, scope }, index) => {
					return (
						<Segment key={type + scope}>
							<Label basic color="purple">
							<Icon name="sn-logo" />{ scope }</Label>
							<Label basic><Icon name="user" />{ type }</Label>
							<Button
								size="mini"
								icon="sn-bin"
								floated="right"
								className="warning"
								onClick={() => {
									this.setState({
										user: {
											...this.state.user,
											roles: this.state.user.roles.filter((role, innerIndex) => innerIndex !== index)
										}
									});
								}}
							/>
						</Segment>
					);
				})}
{/*				<Segment><Label basic color="purple"><Icon name="sn-logo" />System</Label> <Label basic><Icon name="user" />Supporter</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>
				<Segment><Label basic color="blue"><Icon name="sn-sphere" />Svift.net</Label> <Label basic><Icon name="user" />Writer</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>
				<Segment><Label basic color="blue"><Icon name="sn-sphere" />Figuro.dk</Label> <Label basic><Icon name="user" />Administrator</Label> <Button size="mini" icon="setting" floated="right" className="info" /></Segment>*/}
			</SegmentGroup>
		);
	}

	render() {
		const { email, firstName, lastName, isBetaTester, isSuspended, maxOfflineSites, _id = this.props.selectedUser._id /* _id is not in details object (look at constructor) */} = this.state.user;

		return (
			<Modal
				dimmer="blurring"
				size="small"
				open={true}
				closeIcon={
					<Icon name="close" onClick={this.props.onClose} />
				}
			>
				<ModalHeader>
					{/* transl8 */}
					User settings
				</ModalHeader>

				<Form>
					{this.state.loading &&
						<Dimmer active inverted>
							<Loader size="massive" style={{ fontSize: 40 }}>
								Saving...
							</Loader>
						</Dimmer>
					}

					<Grid padded centered>
						<GridColumn mobile={7}>
						<Message>
							<p style={{ lineHeight: 1.8, fontSize: 14 }}>
								<strong><Icon name="user" />{`${firstName} ${lastName}`}</strong><br />
								<Icon name="mail" />{email}<br />
								<Divider />
								<Icon name="time" /> Created at: N/A <br />
								<Icon name="sn-sphere" /> Part of: N/A sites<br />
								<Icon name="sn-sphere" /> Owner of: N/A sites<br />
							</p>
						</Message>
						</GridColumn>
						<GridColumn mobile={5}>
							<FormInput
								value={maxOfflineSites}
								onChange={(e, { value }) => {
									if (isNaN(value)) {
										return;
									}

									this.setState({
										user: {
											...this.state.user,
											maxOfflineSites: Math.floor(value)
										}
									});
								}}
								placeholder="max sites"
								icon="sn-sphere"
								label="Max offline sites"
							/>
							<Divider />
							<Checkbox
								label="Beta tester"
								checked={isBetaTester}
								onChange={() => {
									this.setState({
										user: {
											...this.state.user,
											isBetaTester: !this.state.user.isBetaTester
										}
									});
								}}
							/>
							<Divider />
							<Checkbox
								checked={isSuspended}
								label="Suspend user"
								onChange={() => {
									this.setState({
										user: {
											...this.state.user,
											isSuspended: !this.state.user.isSuspended
										}
									});
								}}
							/>
						</GridColumn>
						<GridColumn mobile={16}>
							<Header as="h3" style={{ boxShadow: 'none' }}>
								<Icon name="sn-star-empty" />
								User discount
							</Header>

							{this.renderDiscountInput()}
							{this.renderDiscounts()}
						</GridColumn>
						<GridColumn mobile={16}>
							<Header as="h3" style={{ boxShadow: 'none' }}>
								<Icon name="sn-key" /> User permissions
							</Header>

							{this.renderPermissionInput()}
							{this.renderPermissions()}
						</GridColumn>
					</Grid>
						
					<Grid verticalAlign="middle" centered>
						<GridColumn mobile={12} textAlign="left">
							<Button
								icon
								size="medium"
								className="positive"
								onClick={async () => {
									this.setState({
										loading: true
									});

									await this.props.editUser({ userId: _id }, this.state.user);

									this.setState({
										loading: false
									});
								}}
							>
								<Icon name="sn-cloud-upload" /> Save changes
							</Button>
							<Button
								icon
								basic
								size="medium"
								className="negative"
								floated="right"
								onClick={async () => {
									await this.props.deleteUser({ userId: _id });

									this.props.onClose();
								}}
							>
								<Icon name="sn-bin" /> Delete user
							</Button>
						</GridColumn>
					</Grid>
				</Form>
			</Modal>
		);
	}
}

export default EditUser;

