import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as ImportForm } from 'semantic-ui-react';
import excludeKeys from '../../../../../utils/excludeKeys';

class FormGroup extends Component {
  render() {
    return (
      <ImportForm.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportForm.Group>
    );
  }
}

export default FormGroup;

FormGroup.propTypes = {
  children: PropTypes.node,         // FormGroup has flexible content
};
