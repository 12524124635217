import React, { PureComponent } from 'react';
import api from 'api';
import {
	Dropdown
} from 'svift-ui';

export default class CouponDropdown extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			coupons: null,
			selectedCoupon: null
		};
	}

	async componentDidMount() {
		const coupons = await api.system.getCoupons();

		this.setState({
			coupons
		});
	}

	render() {
		if (this.state.coupons === null) return 'Fetching coupons...';

		return (
			<Dropdown
				item
				placeholder="Coupon"
				options={this.state.coupons.map(coupon => {
					return {
						text: coupon.id,
						value: coupon.id
					};
				})}
				value={this.props.selectedCoupon}
				onChange={(e, { value }) => {
					this.props.selectCoupon(value);
				}}
			/>
		);
	}
}