import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card as ImportCard } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Card extends Component {
  render() {
    return (
      <ImportCard {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard>
    );
  }
}

export class CardContent extends Component {
  render() {
    return (
      <ImportCard.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard.Content>
    );
  }
}

export class CardDescription extends Component {
  render() {
    return (
      <ImportCard.Description {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard.Description>
    );
  }
}

export class CardGroup extends Component {
  render() {
    return (
      <ImportCard.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard.Group>
    );
  }
}

export class CardHeader extends Component {
  render() {
    return (
      <ImportCard.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard.Header>
    );
  }
}

export class CardMeta extends Component {
  render() {
    return (
      <ImportCard.Meta {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportCard.Meta>
    );
  }
}

Card.propTypes = {
  children: PropTypes.node,         // Card has flexible content
};

CardContent.propTypes = {
  children: PropTypes.node,         // CardContent has flexible content
};

CardDescription.propTypes = {
  children: PropTypes.node,         // CardDescription has flexible content
};

CardGroup.propTypes = {
  children: PropTypes.node,         // CardGroup has flexible content
};

CardHeader.propTypes = {
  children: PropTypes.node,         // CardHeader has flexible content
};

CardMeta.propTypes = {
  children: PropTypes.node,         // CardMeta has flexible content
};
