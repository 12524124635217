import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../components/elements/Label';
import { Icon } from '../components/elements/Icon';

class AppFooter extends Component {
  render() {
    const { children, versionNumber } = this.props;

    return (
      <div id="app-footer">
        <div style={{ marginBottom: 16 }}>
          {children}
        </div>
        <Icon name="sn-logo" className="primary" style={{ opacity: 0.3, fontSize: 40, margin: 0 }} />
        <p style={{ marginBottom: 6, fontSize: 18 }}>svift.net</p>
        <Label circular basic size="small" className="info">version {versionNumber}</Label>
      </div>
    );
  }
}

AppFooter.propTypes = {
  children: PropTypes.node, // AppFooter has flexible content
  versionNumber: PropTypes.string
};

AppFooter.defaultProps = {
  versionNumber: 'X',
};

export default AppFooter;
