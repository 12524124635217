import constants from 'store/constants';
import api from 'api';

const actions = {
	setActiveContent: route => {
		return {
			type: constants.SET_ACTIVE_CONTENT,
			data: route
		};
	},

	inviteUser: ({ email }) => async (dispatch) => {
		await api.users.inviteUser({ email });

		await actions.fetchUsers()(dispatch);
	},

	editUser: ({ userId }, {
		email,
		maxOfflineSites,
		isBetaTester,
		isSuspended,
		coupons,
		roles
	}) => async (dispatch) => {
		await api.users.editUser({ userId }, {
			email,
			maxOfflineSites,
			isBetaTester,
			isSuspended,
			coupons,
			roles
		});

		await actions.fetchUsers()(dispatch);
	},

	deleteUser: ({ userId }) => async (dispatch) => {
		await api.users.deleteUser({ userId });

		await actions.fetchUsers()(dispatch);
	},

	fetchUsers: () => async (dispatch) => {
		const users = await api.users.getUsers();

		dispatch({
			type: constants.SET_USERS,
			data: users
		});
	},

	fetchSites: () => async (dispatch) => {
		const sites = await api.sites.getSites();

		dispatch({
			type: constants.SET_SITES,
			data: sites
		});
	},
};

export default actions;