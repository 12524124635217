import React, { Component } from 'react';
import { Table as ImportTable } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Table extends Component {
  render() {
    return (
      <ImportTable {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable>
    );
  }
}

export class TableBody extends Component {
  render() {
    return (
      <ImportTable.Body {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.Body>
    );
  }
}

export class TableCell extends Component {
  render() {
    return (
      <ImportTable.Cell {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.Cell>
    );
  }
}

export class TableFooter extends Component {
  render() {
    return (
      <ImportTable.Footer {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.Footer>
    );
  }
}

export class TableHeader extends Component {
  render() {
    return (
      <ImportTable.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.Header>
    );
  }
}

export class TableHeaderCell extends Component {
  render() {
    return (
      <ImportTable.HeaderCell {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.HeaderCell>
    );
  }
}

export class TableRow extends Component {
  render() {
    return (
      <ImportTable.Row {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportTable.Row>
    );
  }
}
