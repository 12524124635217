import React, { Component } from 'react';
import { Header as ImportHeader } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Header extends Component {
  render() {
    return (
      <ImportHeader {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportHeader>
    );
  }
}

export class HeaderContent extends Component {
  render() {
    return (
      <ImportHeader.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportHeader.Content>
    );
  }
}

export class HeaderSubheader extends Component {
  render() {
    return (
      <ImportHeader.Subheader {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportHeader.Subheader>
    );
  }
}
