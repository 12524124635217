import React, { Component } from 'react';
import { Comment as ImportComment } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Comment extends Component {
  render() {
    return (
      <ImportComment {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment>
    );
  }
}

export class CommentAuthor extends Component {
  render() {
    return (
      <ImportComment.Author {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Author>
    );
  }
}

export class CommentAction extends Component {
  render() {
    return (
      <ImportComment.Action {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Action>
    );
  }
}

export class CommentActions extends Component {
  render() {
    return (
      <ImportComment.Actions {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Actions>
    );
  }
}

export class CommentAvatar extends Component {
  render() {
    return (
      <ImportComment.Avatar {...excludeKeys(this.props)} />
    );
  }
}

export class CommentContent extends Component {
  render() {
    return (
      <ImportComment.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Content>
    );
  }
}

export class CommentGroup extends Component {
  render() {
    return (
      <ImportComment.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Group>
    );
  }
}

export class CommentMetadata extends Component {
  render() {
    return (
      <ImportComment.Metadata {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Metadata>
    );
  }
}

export class CommentText extends Component {
  render() {
    return (
      <ImportComment.Text {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportComment.Text>
    );
  }
}
