import React, { PureComponent } from 'react';
import { Menu, MenuItem, MenuMenu, ShortcutSectionItem } from 'svift-ui';
import { connect } from 'react-redux';
import actions from 'store/actions/general';
import api from 'api';
import config from 'config';

const mapStateToProps = (state) => {
	return {
		activeContent: state.data.activeContent
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveContent: route => dispatch(actions.setActiveContent(route))
	};
};

class Header extends PureComponent {
	render() {
		// return (
		// 	<MenuMenu id="shortcut-section">
		// 		<MenuItem>
		// 		<Popup
		// 			trigger={
		// 				<Button
		// 					icon
		// 					onClick={() => match === null && setRoute(path)}
		// 					className={isActive ? 'active-app' : ''}
		// 				>
		// 					<Icon name={icon} />
		// 				</Button>
		// 			}
		// 			content={name}
		// 			position="bottom center"
		// 		/>
		// 			<ShortcutSectionItem
		// 				match={matchPath(pathname, '/dashboard/:siteid/pages')}
		// 				changeRoute={changeRoute}
		// 				icon="sn-stack-text"
		// 				name={intl.formatMessage({ id: 'pages.app title' })}
		// 				path={`/dashboard/${siteid}/pages/${frontPage}`}
		// 			/>
		// 			<ShortcutSectionItem
		// 				match={matchPath(pathname, '/dashboard/:siteid/design')}
		// 				changeRoute={changeRoute}
		// 				icon="sn-bucket"
		// 				name={intl.formatMessage({ id: 'design.app title' })}
		// 				path={`/dashboard/${siteid}/design/${liveTheme}`}
		// 			/>
		// 			<ShortcutSectionItem
		// 				match={matchPath(pathname, '/dashboard/:siteid/files')}
		// 				changeRoute={changeRoute}
		// 				icon="sn-folder6"
		// 				name={intl.formatMessage({ id: 'files.app title' })}
		// 				path={`/dashboard/${siteid}/files`}
		// 			/>
		// 			<ShortcutSectionItem
		// 				match={matchPath(pathname, '/dashboard/:siteid/settings')}
		// 				changeRoute={changeRoute}
		// 				icon="sn-cog3"
		// 				name={intl.formatMessage({ id: 'settings.app title' })}
		// 				path={`/dashboard/${siteid}/settings`}
		// 			/>
		// 		</MenuItem>
		// 	</MenuMenu>
		// );

		return (
			<Menu id="top-navigation">
				<MenuItem
					onClick={() => {
						window.location.replace(config.adminDomain);
					}}
				>
					Back to svift.net
				</MenuItem>
				<MenuItem
					onClick={() => this.props.setActiveContent('sites')}
					style={{ backgroundColor: this.props.activeContent === 'sites' ? 'white' : 'lightskyblue' }}
				>
					Sites
				</MenuItem>
				<MenuItem
					onClick={() => this.props.setActiveContent('users')}
					style={{ backgroundColor: this.props.activeContent === 'users' ? 'white' : 'lightskyblue' }}
				>
					Users
				</MenuItem>
				<MenuItem
					onClick={() => this.props.setActiveContent('translations')}
					style={{ backgroundColor: this.props.activeContent === 'translations' ? 'white' : 'lightskyblue' }}
				>
					Translations
				</MenuItem>
				<MenuItem
					onClick={() => this.props.setActiveContent('superadmin')}
					style={{ backgroundColor: this.props.activeContent === 'superadmin' ? 'white' : 'lightskyblue' }}
				>
					Superadmin
				</MenuItem>
				<MenuMenu position="right">
					<MenuItem
						onClick={async () => {
							await api.users.logout();

							window.location.replace('http://dev.admin.svift.net:3000');
						}}
					>
						Log out
					</MenuItem>
				</MenuMenu>
			</Menu>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);