import React, { Component, PureComponent } from 'react';
import ReactDOM, { unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer } from 'react-dom'; // https://twitter.com/soprano/status/737316379712331776
import PropTypes from 'prop-types';

class Portal extends PureComponent {
    componentDidMount() {
        this.node = document.createElement('div');
        this.node.className = 'svift-tooltip';
        document.body.appendChild(this.node);
        this.renderPortal(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.renderPortal(nextProps);
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.node);
        document.body.removeChild(this.node);
    }

    renderPortal = (props) => {
        renderSubtreeIntoContainer(this, props.children, this.node);
    }

    render() {
        return null;
    }
}

class Tooltip extends PureComponent {
    constructor() {
        super();

        this.state = {
            targetNode: null,
            container: null
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            targetNode: ReactDOM.findDOMNode(nextProps.target),
            container: null // May be able to optimize this more if needed
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const containerChanged = prevState.container !== this.state.container;

        if(containerChanged) {
            this.forceUpdate();
        }
    }

    calculatePosition() {
        const targetNode = this.state.targetNode;

        const { height: tooltipHeight, width: tooltipWidth } = this.state.container.getBoundingClientRect();

        const { top, bottom, left, right, width, height } = targetNode.getBoundingClientRect();

        const scrollTop = document.body.scrollTop;

        const style = {
            position: 'absolute',
            zIndex: this.props.zIndex,
            background: 'white',
/*          borderColor: 'black',
            borderRadius: '5px',
            borderWidth: '1px',
            borderStyle: 'solid',*/
            //padding: this.props.padding,
            top: top - (tooltipHeight) + scrollTop,// - this.props.offsetY - this.props.padding * 2,
            left: left + (width / 2) - (tooltipWidth / 2),// - this.props.padding
        };

        return style;
    }

    getContainerStyles() {
        if (!this.state.container) {
            return {position: 'absolute', top: 0, left: 0, opacity: 0}; // Rendering an empty container that we use to calculate the dimensions of the tooltip
        } else {
            return this.calculatePosition();
        }
    }

    renderContent() {
        const content = this.props.open ? this.props.content : this.props.hoverContent;

        const containerStyles = this.getContainerStyles();

        return (
            <div key="tooltip" className={this.props.position} style={containerStyles} onClick={this.props.onClick} ref={(ref) => this.setState({container: ref})}>
                {content}
            </div>
        );
    }

    render() {
        if(!this.props.open && !this.props.hover) return null;
        if(!this.props.target) return null;

        return (
            <Portal>
                {this.renderContent()}
            </Portal>
        );
    }
}

Tooltip.defaultProps = {
    offsetY: 0,
    padding: 10,
    zIndex: 10000,
    position: 'top center'
};

Tooltip.propTypes = {
    offsetY: PropTypes.number,
    target: PropTypes.object,
    position: PropTypes.string,
    open: PropTypes.bool,
    hover: PropTypes.bool,
    content: PropTypes.node,
    hoverContent: PropTypes.node
};

// cool little utility to make it easy to assign tooltips to elements
function tooltip() {
    const _this = this;
    let target = null;

    return {
        setTarget: (element) => {
            target = element;
            _this.forceUpdate();
        },
        getTarget: () => target
    };
}

export {tooltip}

export default Tooltip;