import React, { Component } from 'react';
import { Radio as ImportRadio } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

class Radio extends Component {
  render() {
    return (
      <div>
        <ImportRadio {...excludeKeys(this.props)} />
      </div>
    );
  }
}

export default Radio;
