import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Suggested new format to allow for better scalability of reducer/actions:
import sectionsReducer from 'components/sections/redux/reducer';

// dataReducer is the main reducer until we need to spread it out a bit; I don't suspect we'll need that for a while though. Superadmin is a lot simpler than Admin.
import generalReducer from 'store/reducers/general';

const appReducer = combineReducers({
	data: generalReducer
	//sections: sectionsReducer
});

const rootReducer = (state, action) => {
	// Reset state on log out
	if (action.type === 'LOGOUT') {
		state = {};
	}

	return appReducer(state, action);
};

const defaultState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // Remember to remove devtools in production build

const enhancers = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, defaultState, enhancers);

export default store;
