import React from 'react';
import PropTypes from 'prop-types';

class WizardStep extends React.Component {
  render() {
    return <div className="wizard-content">{this.props.children}</div>;
  }
}

WizardStep.propTypes = {
  children: PropTypes.node,
};

export default WizardStep;
