import React, { Component } from 'react';
import { Feed as ImportFeed } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Feed extends Component {
  render() {
    return (
      <ImportFeed {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed>
    );
  }
}

export class FeedContent extends Component {
  render() {
    return (
      <ImportFeed.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Content>
    );
  }
}

export class FeedDate extends Component {
  render() {
    return (
      <ImportFeed.Date {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Date>
    );
  }
}

export class FeedEvent extends Component {
  render() {
    return (
      <ImportFeed.Event {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Event>
    );
  }
}

export class FeedExtra extends Component {
  render() {
    return (
      <ImportFeed.Extra {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Extra>
    );
  }
}

export class FeedLabel extends Component {
  render() {
    return (
      <ImportFeed.Label {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Label>
    );
  }
}

export class FeedLike extends Component {
  render() {
    return (
      <ImportFeed.Like {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Like>
    );
  }
}

export class FeedMeta extends Component {
  render() {
    return (
      <ImportFeed.Meta {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Meta>
    );
  }
}

export class FeedSummary extends Component {
  render() {
    return (
      <ImportFeed.Summary {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.Summary>
    );
  }
}

export class FeedUser extends Component {
  render() {
    return (
      <ImportFeed.User {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportFeed.User>
    );
  }
}
