import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Item as ImportItem } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Item extends Component {
  render() {
    return (
      <ImportItem {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem>
    );
  }
}

export class ItemContent extends Component {
  render() {
    return (
      <ImportItem.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Content>
    );
  }
}

export class ItemDescription extends Component {
  render() {
    return (
      <ImportItem.Description {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Description>
    );
  }
}

export class ItemExtra extends Component {
  render() {
    return (
      <ImportItem.Extra {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Extra>
    );
  }
}

export class ItemGroup extends Component {
  render() {
    return (
      <ImportItem.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Group>
    );
  }
}

export class ItemHeader extends Component {
  render() {
    return (
      <ImportItem.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Header>
    );
  }
}

export class ItemImage extends Component {
  render() {
    return (
      <ImportItem.Image {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Image>
    );
  }
}

export class ItemMeta extends Component {
  render() {
    return (
      <ImportItem.Meta {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportItem.Meta>
    );
  }
}

Item.propTypes = {
  children: PropTypes.node,         // Item has flexible content
};

ItemContent.propTypes = {
  children: PropTypes.node,         // ItemContent has flexible content
};

ItemDescription.propTypes = {
  children: PropTypes.node,         // ItemDescription has flexible content
};

ItemExtra.propTypes = {
  children: PropTypes.node,         // ItemExtra has flexible content
};

ItemGroup.propTypes = {
  children: PropTypes.node,         // ItemGroup has flexible content
};

ItemHeader.propTypes = {
  children: PropTypes.node,         // ItemHeader has flexible content
};

ItemImage.propTypes = {
  children: PropTypes.node,         // ItemImage has flexible content
};

ItemMeta.propTypes = {
  children: PropTypes.node,         // ItemMeta has flexible content
};
