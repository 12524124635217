import React, { PureComponent } from 'react';
import { AppHeader, Grid, GridRow, GridColumn, Icon, Header, HeaderContent, HeaderSubheader } from 'svift-ui';
// import { connect } from 'react-redux';
// import Menu from 'components/sections/Menu';
// import Section from 'components/sections/Section';
// import Sections from 'components/sections/Sections';
import api from 'api';

// const mapStateToProps = (state) => {
// 	return {
// 		roles: state.data.user.roles
// 	};
// };

class Superadmin extends PureComponent {
	componentDidMount() {
		api.system.getLogs().then(logs => {
			console.log(logs, 'LOGS');
		}).catch(console.log.bind('error'));
	}

	render() {
		return (
			<div>
				<AppHeader>
					<Grid columns={16} verticalAlign="middle">
						<GridRow>
							<GridColumn computer={10}>
								<Header as="h4">
									<Icon name="sn-sphere" />
									<HeaderContent>
										Superadmin
										<HeaderSubheader>
											Superadmin tools
										</HeaderSubheader>
									</HeaderContent>
								</Header>
							</GridColumn>
						</GridRow>
					</Grid>
				</AppHeader>

				<button onClick={() => api.system.restartServer().catch(console.log)}>
					REDEPLOY - WILL PUT DOWN THE SERVER TEMPORARILY
				</button>
			</div>
		);
	}
}

export default Superadmin;