import React, { Component } from 'react';
import { Image as ImportImage } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Image extends Component {
  render() {
    return (
      <ImportImage {...excludeKeys(this.props)} >
        { this.props.children }
      </ImportImage>
    );
  }
}

export class ImageGroup extends Component {
  render() {
    return (
      <ImportImage.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportImage.Group>
    );
  }
}

export class ImagePlaceholder extends Component {
  render() {
    return (
      <ImportImage {...excludeKeys(this.props)} src="http://placehold.it/500" >
        { this.props.children }
      </ImportImage>
    )
  }
}
