import React, { Component } from 'react';
import { Accordion as ImportAccordion } from 'semantic-ui-react';

export class Accordion extends Component {
	render() {
		return (
			<ImportAccordion {...this.props}>
				{this.props.children}
			</ImportAccordion>
		);
	}
}

export class AccordionContent extends Component {
	render() {
		return (
			<ImportAccordion.Content {...this.props}>
				{this.props.children}
			</ImportAccordion.Content>
		);
	}
}

export class AccordionTitle extends Component {
	render() {
		return (
			<ImportAccordion.Title {...this.props}>
				{this.props.children}
			</ImportAccordion.Title>
		);
	}
}
