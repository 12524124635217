import React, { Component } from 'react';

export default class Tile extends Component {
  render() {
    return (
      <div
        id={this.props.id}
        className="app-tile"
        onClick={this.props.onClick}
      >
        <div className={this.props.className} >
          {this.props.children}
        </div>
      </div>
    );
  }
}
