import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon as ImportIcon } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Icon extends Component {
  render() {
    return (
      <ImportIcon {...excludeKeys(this.props)} />
    );
  }
}

export class IconGroup extends Component {
  render() {
    return (
      <ImportIcon.Group {...excludeKeys(this.props)} />
    );
  }
}

ImportIcon.propTypes = {
  name: PropTypes.string,
};