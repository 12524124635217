import React, { PureComponent } from 'react';

import { Button } from './elements/Button';

export default class WizardControls extends PureComponent {
  render() {
    const { prevButton, nextButton, prevText, nextText, onPrev, onNext, styles = {} } = this.props;

    let justifyContent = 'space-between';

    if (!prevButton && nextButton) {
      justifyContent = 'flex-end';
    }

    return (
      <div style={{ ...styles }}>
        {this.props.renderPrevButton ? this.props.renderPrevButton() : (this.props.prevButton && <Button onClick={onPrev} primary>{prevText || 'Tilbage'}</Button>)}
        {this.props.renderNextButton ? this.props.renderNextButton() : (this.props.nextButton && <Button onClick={onNext} primary>{nextText || 'Næste'}</Button>)}
      </div>
    );
  }
}