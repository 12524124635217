import React, { Component } from 'react';
import { Button as ImportButton } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';
import radium from 'radium';

const EnhancedButton = radium(ImportButton);

class CustomButton extends Component {
  render() {
    return (
      <EnhancedButton {...excludeKeys(this.props)}>
        {this.props.children}
      </EnhancedButton>
    );
  }
}

const Button = radium(CustomButton);

export { Button };

export class ButtonContent extends Component {
  render() {
    return (
      <ImportButton.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportButton.Content>
    );
  }
}

export class ButtonGroup extends Component {
  render() {
    return (
      <ImportButton.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportButton.Group>
    );
  }
}

export class ButtonOr extends Component {
  render() {
    return (
      <ImportButton.Or {...excludeKeys(this.props)} />
    );
  }
}
