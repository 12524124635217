import React, { Component } from 'react';
import { Input as ImportInput } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';
import radium from 'radium';

const EnhancedInput = radium(ImportInput);

export class Input extends Component {
  render() {
    return (
      <EnhancedInput {...excludeKeys(this.props)}>
        { this.props.children }
      </EnhancedInput>
    );
  }
}

class BasicInput extends Component {
  render() {
    return (
      <input {...this.props} />
    );
  }
}

class BasicTextArea extends Component {
  render() {
    return (
      <textarea {...this.props} />
    );
  }
}

const RadiumInput = radium(BasicInput);
const RadiumTextArea = radium(BasicTextArea);

export {
	RadiumInput,
	RadiumTextArea
};
