import React, { Component } from 'react';
import { Step as ImportStep } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Step extends Component {
  render() {
    return (
      <ImportStep {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportStep>
    );
  }
}

export class StepContent extends Component {
  render() {
    return (
      <ImportStep.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportStep.Content>
    );
  }
}

export class StepDescription extends Component {
  render() {
    return (
      <ImportStep.Description {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportStep.Description>
    );
  }
}

export class StepGroup extends Component {
  render() {
    return (
      <ImportStep.Group {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportStep.Group>
    );
  }
}

export class StepTitle extends Component {
  render() {
    return (
      <ImportStep.Title {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportStep.Title>
    );
  }
}
