import React, { Component } from 'react';
import { Grid as ImportGrid } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';
import PropTypes from 'prop-types';

export class Grid extends Component {
  render() {
    return (
      <ImportGrid {...excludeKeys(this.props)}>
        { this.props.children }
        { this.props.buttons && this.props.buttons() }
      </ImportGrid>
    );
  }
}



export class GridColumn extends Component {
  render() {
    return (
      <ImportGrid.Column {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportGrid.Column>
    );
  }
}

export class GridRow extends Component {
  render() {
    return (
      <ImportGrid.Row {...excludeKeys(this.props)}>
        { this.props.children }
        { this.props.buttons && this.props.buttons() }
      </ImportGrid.Row>
    );
  }
}
