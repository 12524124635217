import React, { Component } from 'react';
import { Tab as ImportTab } from 'semantic-ui-react';

export class Tab extends Component {
	render() {
		return (
			<ImportTab {...this.props} />
		);
	}
}

export class TabPane extends Component {
	render() {
		return (
			<ImportTab.Pane {...this.props}>
				{ this.props.children }
			</ImportTab.Pane>
		);
	}
}