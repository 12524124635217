import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu as ImportMenu } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';
import radium from 'radium';

const RadiumMenuItem = radium(ImportMenu.Item);

export class Menu extends Component {
  render() {
    return (
      <ImportMenu {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMenu>
    );
  }
}

export class MenuHeader extends Component {
  render() {
    return (
      <ImportMenu.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMenu.Header>
    );
  }
}

export class MenuItem extends Component {
  render() {
    return (
      <RadiumMenuItem {...excludeKeys(this.props)}>
        { this.props.children }
      </RadiumMenuItem>
    );
  }
}

export class MenuMenu extends Component {
  render() {
    return (
      <ImportMenu.Menu {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMenu.Menu>
    );
  }
}

Menu.propTypes = {
  children: PropTypes.node,         // Menu has flexible content
};

MenuHeader.propTypes = {
  children: PropTypes.node,         // MenuHeader has flexible content
};

MenuItem.propTypes = {
  children: PropTypes.node,         // MenuItem has flexible content
};

MenuMenu.propTypes = {
  children: PropTypes.node,         // MenuMenu has flexible content
};
