import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal as ImportModal } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

class Modal extends Component {
  render() {
    return (
      <ImportModal {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportModal>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
};


class ModalHeader extends Component {
  render() {
    return (
      <ImportModal.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportModal.Header>
    );
  }
}

ModalHeader.propTypes = {
  children: PropTypes.node,
};


class ModalContent extends Component {
  render() {
    return (
      <ImportModal.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportModal.Content>
    );
  }
}

ModalContent.propTypes = {
  children: PropTypes.node,
};


class ModalDescription extends Component {
  render() {
    return (
      <ImportModal.Description {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportModal.Description>
    );
  }
}

ModalDescription.propTypes = {
  children: PropTypes.node,
};


class ModalActions extends Component {
  render() {
    return (
      <ImportModal.Actions {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportModal.Actions>
    );
  }
}

ModalActions.propTypes = {
  children: PropTypes.node,
};


export {
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  ModalActions,
};
