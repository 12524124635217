import React, { PureComponent } from 'react';
import { Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Icon, Label, Header, HeaderContent, HeaderSubheader, Input, Button, AppContentSection, AppHeader, Grid, GridRow, GridColumn, Popup } from 'svift-ui';
import { connect } from 'react-redux';
import Edit from './Edit';
import actions from 'store/actions/general';
import { createFilter } from 'react-search-input';

const mapStateToProps = state => {
	return {
		users: state.data.users
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchUsers: () => dispatch(actions.fetchUsers()),
		inviteUser: ({ email }) => dispatch(actions.inviteUser({ email })),
		deleteUser: ({ userId }) => dispatch(actions.deleteUser({ userId })),
		editUser: (...args) => dispatch(actions.editUser(...args)) // Check action creator for precise syntax; cba duplicating that many keys :D
	};
};

class Users extends PureComponent {
	constructor() {
		super();

		this.state = {
			selectedUser: null,
			search: null,
			inviteEmail: ''
		};

		this.renderUserRow = this.renderUserRow.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.sendInvite = this.sendInvite.bind(this);
	}

	onSearchChange(e) {
		this.setState({
			search: e.target.value.length === 0 ? null : e.target.value
		});
	}

	async componentDidMount() {
		await this.props.fetchUsers();
	}

	async sendInvite() {
		/*
			Just making sure we can't invite new users while an invite is in progress
		*/
		if (this.sendingInvite) return;

		this.sendingInvite = true;

		try {
			await this.props.inviteUser({ email: this.state.inviteEmail });
		} catch (e) {
			console.log(e, 'error');

			alert('Invite failed, check log');
		}

		this.setState({
			inviteEmail: ''
		});

		this.sendingInvite = false;
	}

	renderUserRow(user = {}) {
		const { inviteToken, registrationToken, _id } = user;
		const { email, firstName, lastName } = user.details || user;

		let status = {
			text: 'Active',
			color: 'green'
		};

		if (inviteToken) {
			status = {
				text: 'Invitation sent',
				color: 'orange'
			};
		}

		if (registrationToken) {
			status = {
				text: 'Awaiting confirmation',
				color: 'blue'
			};
		}

		const fullName = inviteToken ? '<Pending>' : `${firstName} ${lastName}`;

		return (
			<TableRow key={_id}>
				<TableCell>
					<Header as="h5">
						<Icon name="sn-avatar-anonymous" style={{ fontSize: 32 }} />
						<HeaderContent>
							{fullName}
							<HeaderSubheader>{email}</HeaderSubheader>
						</HeaderContent>
					</Header>
				</TableCell>
				<TableCell>
					<Label basic>
						<Icon name="sn-sphere" /> N/A
					</Label>
				</TableCell>
				<TableCell>
					<Label basic>
						<Icon name="sn-sphere" /> N/A
					</Label>
				</TableCell>
				<TableCell>
					<Label basic color={status.color}>{status.text}</Label>
				</TableCell>
				<TableCell>
					<Label basic>
						<Icon name="clock" /> N/A
					</Label>
				</TableCell>
				{email === 'system@svift.net' ? <TableCell /> :
					<TableCell textAlign="right">
						<Icon
							onClick={() => {
								this.setState({
									selectedUser: user
								});
							}}
							link
							name="setting"
							className="primary"
							style={{ fontSize: 24 }}
						/>
					</TableCell>
				}
			</TableRow>
		);
	}

	render() {
		const { search } = this.state;
		const { users } = this.props;
		const filterBy = ['email'];
		const filteredUsers = search === null ? users : Object.values(users).filter(createFilter(search, filterBy));

		return (
			<div>
				<AppHeader>
					<Grid columns={16} verticalAlign="middle">
						<GridRow>
							<GridColumn computer={10}>
								<Header as="h4">
									<Icon name="users" />
									<HeaderContent>
										Users
										<HeaderSubheader>
											svift.net system
										</HeaderSubheader>
									</HeaderContent>
								</Header>
							</GridColumn>
							<GridColumn computer={5}>
								<Input
									fluid
									placeholder="search users"
									icon="search"
									value={this.state.search}
									onChange={this.onSearchChange}
								/>
							</GridColumn>
							<GridColumn computer={1} textAlign="right">
								<Button circular basic primary icon="filter outline" />
							</GridColumn>
							{/*<GridColumn computer={4} textAlign="right">
								<Popup
									trigger={
										<Button basic positive size="medium" icon="plus" content="Invite new user" />
									}
									content="Create new user(s)"
									position="bottom center"
								/>
							</GridColumn>*/}
						</GridRow>
					</Grid>
				</AppHeader>

				<AppContentSection>
					<Table padded stackable selectable>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>
									Name
								</TableHeaderCell>
								<TableHeaderCell>
									Part of
								</TableHeaderCell>
								<TableHeaderCell>
									Owner of
								</TableHeaderCell>
								<TableHeaderCell>
									Status
								</TableHeaderCell>
								<TableHeaderCell>
									Created
								</TableHeaderCell>
								<TableHeaderCell textAlign="right">
									Options
								</TableHeaderCell>
							</TableRow>
						</TableHeader>

						<TableBody>
							{filteredUsers.map(this.renderUserRow)}

							<TableRow>
								<TableCell>
									<Header as="h5">
										<Icon name="sn-avatar-anonymous" style={{ fontSize: 32 }} />
										<HeaderContent style={{ minWidth: 132, width: '100%' }}>
											<Input
												value={this.state.inviteEmail}
												onChange={e => {
													this.setState({
														inviteEmail: e.target.value
													});
												}}
												fluid
												placeholder="user e-mail"
												size="mini"
												style={{ marginTop: 4 }}
											/>
										</HeaderContent>
									</Header>
								</TableCell>
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell>
									<Button fluid size="mini" positive onClick={this.sendInvite}>
										<Icon name="sn-paperplane" />
										Send invite
									</Button>
								</TableCell>
								<TableCell textAlign="right">
									<Icon name="sn-cross2" size="small" link="#" style={{ fontSize: 16 }} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</AppContentSection>

				{this.state.selectedUser !== null &&
					<Edit
						selectedUser={this.state.selectedUser}
						deleteUser={this.props.deleteUser}
						editUser={this.props.editUser}
						onClose={() => {
							this.setState({
								selectedUser: null
							});
						}}
					/>
				}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
