// SUI component styles
// import 'semantic-ui-css/components/grid.min.css';
// import 'semantic-ui-css/components/button.min.css';
// import 'semantic-ui-css/components/header.min.css';
// import 'semantic-ui-css/components/icon.min.css';
// import 'semantic-ui-css/components/input.min.css';
// import 'semantic-ui-css/components/checkbox.min.css';
// import 'semantic-ui-css/components/accordion.min.css';
// import 'semantic-ui-css/components/menu.min.css';
// import 'semantic-ui-css/components/divider.min.css';
// import 'semantic-ui-css/components/dropdown.min.css';
// import 'semantic-ui-css/components/label.min.css';
// import 'semantic-ui-css/components/modal.min.css';
// import 'semantic-ui-css/components/list.min.css';
// import 'semantic-ui-css/components/segment.min.css';
// import 'semantic-ui-css/components/loader.min.css';
// import 'semantic-ui-css/components/table.min.css';
// import 'semantic-ui-css/components/message.min.css';
// import 'semantic-ui-css/components/flag.min.css';
// import 'semantic-ui-css/components/step.min.css';
// import 'semantic-ui-css/components/search.min.css';
// import 'semantic-ui-css/components/item.min.css';
// import 'semantic-ui-css/components/popup.min.css';
// import 'semantic-ui-css/components/progress.min.css';
// import 'semantic-ui-css/components/reveal.min.css';
// import 'semantic-ui-css/components/sidebar.min.css';
// import 'semantic-ui-css/components/tab.min.css';
// import 'semantic-ui-css/components/dimmer.min.css';
// import 'semantic-ui-css/components/container.min.css';
// import 'semantic-ui-css/components/comment.min.css';
// import 'semantic-ui-css/components/card.min.css';
// import 'semantic-ui-css/components/breadcrumb.min.css';
// import 'semantic-ui-css/components/form.min.css';

// SUI Styling
// TO DO: Remove general SUI-min CSS and replace with specific component styling (CSS MODULES)
import 'semantic-ui-css/semantic.min.css';

// reset + index
import './styling/base/reset.less';
import './styling/index.less';

// Containers
import AppContainer from './containers/AppContainer';
// import FlexWrapper from './containers/FlexWrapper'; // To be removed (no longer used in Admin)
import SideNav from './containers/SideNav';
import AppContent from './containers/AppContent';
import AppHeader from './containers/AppHeader';
import AppContentSection from './containers/AppContentSection';
import AppFooter from './containers/AppFooter';
import UserNav from './containers/UserNav';
import Canvas from './containers/Canvas';
import Box from './containers/Box';
import Tile from './containers/Tile';
// import ElementWrapper from './containers/ElementWrapper'; // To be removed
import { Container } from './containers/elements/Container';
import { Divider } from './containers/elements/Divider';
import {
    Header,
    HeaderContent,
    HeaderSubheader
} from './containers/elements/Header';

// Collection
import {
    Breadcrumb,
    BreadcrumbDivider,
    BreadcrumbSection
} from './components/collections/Breadcrumb';
import {
    Form,
    FormField,
    FormButton,
    FormCheckbox,
    FormDropdown,
    FormGroup,
    FormInput,
    FormRadio,
    FormSelect,
    FormTextArea
} from './components/collections/form/index';
import { Grid, GridColumn, GridRow } from './components/collections/Grid';
import {
    Menu,
    MenuHeader,
    MenuItem,
    MenuMenu
} from './components/collections/Menu';
import {
    Message,
    MessageContent,
    MessageHeader,
    MessageList,
    MessageItem
} from './components/collections/Message';
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow
} from './components/collections/Table';

// Elements
import {
    Button,
    ButtonContent,
    ButtonGroup,
    ButtonOr
} from './components/elements/Button';
import Link from './components/elements/Link';
import { Flag } from './components/elements/Flag';
import { Icon, IconGroup } from './components/elements/Icon';
import {
    Image,
    ImageGroup,
    ImagePlaceholder
} from './components/elements/Image';
import {
    Input,
    RadiumInput,
    RadiumTextArea
} from './components/elements/Input';
import { Label, LabelDetail, LabelGroup } from './components/elements/Label';
import {
    List,
    ListContent,
    ListDescription,
    ListHeader,
    ListIcon,
    ListItem,
    ListList
} from './components/elements/List';
import { Rail } from './components/elements/Rail';
import { Reveal, RevealContent } from './components/elements/Reveal';
import { Segment, SegmentGroup } from './components/elements/Segment';
import {
    Step,
    StepContent,
    StepDescription,
    StepGroup,
    StepTitle
} from './components/elements/Step';

// Views
import {
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    CardMeta
} from './components/views/Card';
import {
    Comment,
    CommentAuthor,
    CommentAction,
    CommentActions,
    CommentAvatar,
    CommentContent,
    CommentGroup,
    CommentMetadata,
    CommentText
} from './components/views/Comment';
import {
    Feed,
    FeedContent,
    FeedDate,
    FeedEvent,
    FeedExtra,
    FeedLabel,
    FeedLike,
    FeedMeta,
    FeedSummary,
    FeedUser
} from './components/views/Feed';
import {
    Item,
    ItemContent,
    ItemDescription,
    ItemExtra,
    ItemGroup,
    ItemHeader,
    ItemImage,
    ItemMeta
} from './components/views/Item';
// import { Statistic, StatisticGroup, StatisticLabel, StatisticValue } from './components/views/Statistic';

// Components
import NumberPicker from './components/NumberPicker';
import Slider from './components/Slider';
import Wizard from './components/Wizard';
import WizardStep from './components/WizardStep';
import WizardControls from './components/WizardControls';
import ScrollBox from './components/ScrollBox';
import Loader from './components/elements/Loader';
import Picker from './components/Picker';
import HoverDropZone from './components/HoverDropZone';
import BuildingBlock from './components/elements/BuildingBlock';
import { Tooltip } from './components/Tooltip';

// Addons
import Select from './components/addons/Select';
import Radio from './components/addons/Radio';
import TextArea from './components/addons/TextArea';

// Modules
// import { Video } from './components/modules/Video'; everything imported in this file adds to admin bundle size; video isn't used currently - add it back when relevant
import { Tab, TabPane } from './components/modules/Tab';
import {
    Accordion,
    AccordionContent,
    AccordionTitle
} from './components/modules/Accordion';
import { Checkbox } from './components/modules/Checkbox';
import { Progress } from './components/modules/Progress';
import {
    Dropdown,
    DropdownDivider,
    DropdownHeader,
    DropdownItem,
    DropdownMenu
} from './components/modules/Dropdown';
import { Popup, PopupHeader, PopupContent } from './components/modules/Popup';
import {
    Modal,
    ModalHeader,
    ModalContent,
    ModalDescription,
    ModalActions
} from './components/modules/Modal';
import Dimmer from './components/modules/Dimmer';
import IntroductionIndicator from './components/modules/introduction';

// export library
export {
    NumberPicker,
    Slider,
    HoverDropZone,
    ScrollBox,
    AppContainer,
    // FlexWrapper,
    AppContent,
    AppHeader,
    AppContentSection,
    AppFooter,
    SideNav,
    UserNav,
    Canvas,
    Box,
    Breadcrumb,
    BreadcrumbDivider,
    BreadcrumbSection,
    Form,
    FormField,
    FormButton,
    FormCheckbox,
    FormDropdown,
    FormGroup,
    FormInput,
    FormRadio,
    FormSelect,
    FormTextArea,
    Grid,
    GridColumn,
    GridRow,
    Menu,
    MenuHeader,
    MenuItem,
    MenuMenu,
    Message,
    MessageContent,
    MessageHeader,
    MessageList,
    MessageItem,
    Tab,
    TabPane,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Button,
    ButtonContent,
    ButtonGroup,
    ButtonOr,
    Flag,
    Icon,
    IconGroup,
    Image,
    ImageGroup,
    Input,
    RadiumInput,
    RadiumTextArea,
    Label,
    LabelDetail,
    LabelGroup,
    List,
    ListContent,
    ListDescription,
    ListHeader,
    ListIcon,
    ListItem,
    ListList,
    Loader,
    Rail,
    Reveal,
    RevealContent,
    Segment,
    SegmentGroup,
    Step,
    StepContent,
    StepDescription,
    StepGroup,
    StepTitle,
    Container,
    Divider,
    Header,
    HeaderContent,
    HeaderSubheader,
    Accordion,
    AccordionContent,
    AccordionTitle,
    Checkbox,
    Progress,
    Dropdown,
    DropdownDivider,
    DropdownHeader,
    DropdownItem,
    DropdownMenu,
    Popup,
    PopupHeader,
    PopupContent,
    Modal,
    ModalHeader,
    ModalContent,
    ModalDescription,
    ModalActions,
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    CardMeta,
    Comment,
    CommentAuthor,
    CommentAction,
    CommentActions,
    CommentAvatar,
    CommentContent,
    CommentGroup,
    CommentMetadata,
    CommentText,
    Feed,
    FeedContent,
    FeedDate,
    FeedEvent,
    FeedExtra,
    FeedLabel,
    FeedLike,
    FeedMeta,
    FeedSummary,
    FeedUser,
    Item,
    ItemContent,
    ItemDescription,
    ItemExtra,
    ItemGroup,
    ItemHeader,
    ItemImage,
    ItemMeta,
    // Statistic,
    // StatisticGroup,
    // StatisticLabel,
    // StatisticValue,
    Select,
    Radio,
    TextArea,
    Tile,
    Wizard,
    WizardStep,
    WizardControls,
    Picker,
    ImagePlaceholder,
    BuildingBlock,
    Tooltip,
    // Video,
    // ElementWrapper,
    Dimmer,
    Link,
    IntroductionIndicator
};
