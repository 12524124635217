import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppHeader extends Component {
	render() {
		return (
			<div id="app-header" style={{ textAlign: `${this.props.align}` }} className={this.props.className}>
				{ this.props.children }

				{/* TO DO: {window.pageYOffset > this.height &&
					<p>SHOW THIS!</p>
				} */}
			</div>
		);
	}
}

AppHeader.propTypes = {
	align: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
};

AppHeader.defaultProps = {
	children: <p>No content in AppHeader...</p>,
};

export default AppHeader;
