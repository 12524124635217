import React, { Component } from 'react';
import { Loader as ImportLoader } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export default class Loader extends Component {
  render() {
    if (this.props.text || this.props.children) {
      return (
        <ImportLoader active inline='centered' {...excludeKeys(this.props)}>
          {this.props.text || this.props.children}
        </ImportLoader>
      );
    }

    return <ImportLoader active inline='centered' {...excludeKeys(this.props)} />;
  }
}
