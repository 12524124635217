import React, { Component } from 'react';
import PropTypes from 'prop-types';
import excludeKeys from '../../../utils/excludeKeys';

class Canvas extends Component {
	render() {
		return (
			<div id="canvas" {...excludeKeys(this.props)}>
				{this.props.children}
			</div>
		);
	}
}

Canvas.propTypes = {
	children: PropTypes.node, // Canvas has flexible content
};

export default Canvas;