import React, { Component } from 'react';
import { Divider as ImportDivider } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Divider extends Component {
  render() {
    return (
      <ImportDivider {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportDivider>
    );
  }
}
