import React, { Component } from 'react';
import { Message as ImportMessage } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Message extends Component {
  render() {
    return (
      <ImportMessage {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMessage>
    );
  }
}

export class MessageContent extends Component {
  render() {
    return (
      <ImportMessage.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMessage.Content>
    );
  }
}

export class MessageHeader extends Component {
  render() {
    return (
      <ImportMessage.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMessage.Header>
    );
  }
}

export class MessageList extends Component {
  render() {
    return (
      <ImportMessage.List {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMessage.List>
    );
  }
}

export class MessageItem extends Component {
  render() {
    return (
      <ImportMessage.Item {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportMessage.Item>
    );
  }
}
