import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppContainer extends Component {
	render() {
		return (
			<div id="app-container">
				{this.props.children}
			</div>
		);
	}
}

AppContainer.propTypes = {
	children: PropTypes.node,
};

AppContainer.defaultProps = {
	children: <p>No app content to display...</p>,
};

export default AppContainer;
