import React, { Component } from 'react';
import { Container as ImportContainer } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Container extends Component {
  render() {
    return (
      <ImportContainer {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportContainer>
    );
  }
}
