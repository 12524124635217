import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

class CustomSlider extends Component {

	constructor() {
		super();
		this.state = {
			value: 0,
		};
		this.updateSliderValue = this.updateSliderValue.bind(this);
	}

	updateSliderValue(data) {
		const {onChange} = this.props;
		const {value} = this.state;
		this.setState({ value: data });
		onChange(data);
	}

	render() {
		const { min, max, width, style } = this.props;
		const { value } = this.state;
		const newValue = this.props.value || value;
		const handle = props => <Slider.Handle className={props.className} offset={props.offset} />;

		return <Slider min={min} max={max} value={newValue} onChange={data => this.updateSliderValue(data)} handle={handle} style={style} />;
	}
}

CustomSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

export default CustomSlider;
