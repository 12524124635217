import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AppContent extends Component {
	render() {
		return (
			<div id="app-content">
				{this.props.children}
			</div>
		);
	}
}

AppContent.propTypes = {
	children: PropTypes.node,
};

AppContent.defaultProps = {
	children: <p>No content in AppContent...</p>,
};

export default AppContent;
