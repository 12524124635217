import React, { PureComponent } from 'react';
import { Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Icon, Label, Header, HeaderContent, HeaderSubheader, AppContentSection, AppHeader, Grid, GridRow, GridColumn, Input, Button } from 'svift-ui';
import { connect } from 'react-redux';
import actions from 'store/actions/general';
import config from 'config';

const mapStateToProps = state => {
	return {
		sites: state.data.sites
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchSites: () => dispatch(actions.fetchSites())
	};
};

class Sites extends PureComponent {
	constructor() {
		super();

		this.state = {
			showSettingModal: false,
		};

		this.renderSiteRow = this.renderSiteRow.bind(this);
	}

	async componentDidMount() {
		await this.props.fetchSites();
	}

	renderSubscription(subscription) {
		return (
			<TableCell>
				<Label basic>No Subscription</Label>
			</TableCell>
		);
	}

	renderSiteRow(site, index) {
		const { name, subscription, _id } = site;

		return (
			<TableRow key={index}>
				<TableCell>
					<Header as="h5">
						<Icon
							name="sn-sphere"
							style={{ fontSize: 24, color: 'orange', cursor: 'pointer' }}
							onClick={() => {
								window.location.replace(`${config.adminDomain}/dashboard/${_id}`);
							}}
						/>
							<HeaderContent >
								{name}
							<HeaderSubheader>no domain</HeaderSubheader>
						</HeaderContent>
					</Header>
				</TableCell>
				<TableCell>
					<Label basic color="orange">N/A</Label>
				</TableCell>
				{
					this.renderSubscription(subscription)
				}
				<TableCell>
					<Label basic>
						<Icon name="users" /> N/A
					</Label>
				</TableCell>
				<TableCell textAlign="right">
					<Label basic color="red">
						<Icon name="database" /> N/A
					</Label>
				</TableCell>
				<TableCell textAlign="right">
					<Label basic>
						<Icon name="clock" /> N/A
					</Label>
				</TableCell>
				<TableCell textAlign="right">
					<Icon link name="setting" className="primary" style={{ fontSize: 24 }} />
				</TableCell>
			</TableRow>
		);
	}

	render() {
		return (
			<div>
				<AppHeader>
					<Grid columns={16} verticalAlign="middle">
						<GridRow>
							<GridColumn computer={10}>
								<Header as="h4">
									<Icon name="sn-sphere" />
									<HeaderContent>
										Sites
										<HeaderSubheader>
											svift.net system
										</HeaderSubheader>
									</HeaderContent>
								</Header>
							</GridColumn>
							<GridColumn computer={5}>
								<Input fluid placeholder="search" icon="search" />
							</GridColumn>
							<GridColumn computer={1} textAlign="right">
								<Button circular basic primary icon="filter outline" />
							</GridColumn>
						</GridRow>
					</Grid>
				</AppHeader>

				<AppContentSection>
					<Table padded stackable selectable>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>
									Site title
								</TableHeaderCell>
								<TableHeaderCell>
									Status
								</TableHeaderCell>
								<TableHeaderCell>
									Subscription
								</TableHeaderCell>
								<TableHeaderCell>
									Users
								</TableHeaderCell>
								<TableHeaderCell textAlign="right">
									Data usage
								</TableHeaderCell>
								<TableHeaderCell textAlign="right">
									Subscription date
								</TableHeaderCell>
								<TableHeaderCell textAlign="right">
									Options
								</TableHeaderCell>
							</TableRow>
						</TableHeader>

						<TableBody>
							{this.props.sites.map(this.renderSiteRow)}
						</TableBody>
					</Table>
				</AppContentSection>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sites);

/*
<TableRow>
						<TableCell>
							<Header as="h5">
								<Icon name="sn-sphere" style={{ fontSize: 24, color: 'orange' }} />
									<HeaderContent>
										Site X
									<HeaderSubheader>no domain</HeaderSubheader>
								</HeaderContent>
							</Header>
						</TableCell>
						<TableCell>
							<Label basic color="orange">Offline</Label>
						</TableCell>
						<TableCell>
							<Label basic>No Subscription</Label>
						</TableCell>
						<TableCell>
							<Label basic>
								<Icon name="users" />3
							</Label>
						</TableCell>
						<TableCell textAlign="right">
							<Label basic color="red">
								<Icon name="database" />1.350 MB
							</Label>
						</TableCell>
						<TableCell textAlign="right">
							<Label basic>
								<Icon name="clock" /> Not set
							</Label>
						</TableCell>
						<TableCell textAlign="right">
							<Icon link name="setting" className="primary" style={{ fontSize: 24 }} />
						</TableCell>
					</TableRow>
					<TableRow>
					<TableCell>
					<Header as="h5">
					<Icon name="sn-sphere" style={{ fontSize: 24, color: 'green' }} />
					<HeaderContent>
					Site X
					<HeaderSubheader>domain.x</HeaderSubheader>
					</HeaderContent>
					</Header>
					</TableCell>
					<TableCell>
					<Label basic color="green">Online</Label>
					</TableCell>
					<TableCell>
					<Label basic color="blue">Free</Label> <Label basic color="green">Blog</Label>
					</TableCell>
					<TableCell>
					<Label basic>
					<Icon name="users" />3
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Label basic>
					<Icon name="database" />350 MB
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Label basic>
					<Icon name="clock" /> DD / MM - YYYY
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Icon link name="setting" className="primary" style={{ fontSize: 24 }} />
					</TableCell>
					</TableRow>
					<TableRow>
					<TableCell>
					<Header as="h5">
					<Icon name="sn-sphere" style={{ fontSize: 24, color: 'green' }} />
					<HeaderContent>
					Site X
					<HeaderSubheader>domain.x</HeaderSubheader>
					</HeaderContent>
					</Header>
					</TableCell>
					<TableCell>
					<Label basic color="green">Online</Label>
					</TableCell>
					<TableCell>
					<Label basic color="purple">Pro</Label> <Label basic color="green">Blog</Label> <Label basic color="orange">Shop</Label>
					</TableCell>
					<TableCell>
					<Label basic>
					<Icon name="users" />3
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Label basic>
					<Icon name="database" />750 MB
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Label basic>
					<Icon name="clock" /> DD / MM - YYYY
					</Label>
					</TableCell>
					<TableCell textAlign="right">
					<Icon link name="setting" className="primary" style={{ fontSize: 24 }} />
					</TableCell>
					</TableRow>
*/