import React, { Component } from 'react';
import PropTypes from 'prop-types';
import excludeKeys from '../../../utils/excludeKeys';

class SideNav extends Component {
  render() {
    return (
      <div id="sidenav" className="sidebar">
        <div id="sidenav-content" className="sidebar-content left-sidebar" {...excludeKeys(this.props)}>
          { this.props.children }
        </div>
      </div>
    );
  }
}

SideNav.propTypes = {
  children: PropTypes.node,         // SideNav has flexible content
};

SideNav.defaultProps = {
  children: <p>No content...</p>,
};

export default SideNav;
