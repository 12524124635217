import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as ImportBreadcrumb } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

export class Breadcrumb extends Component {
  render() {
    return (
      <ImportBreadcrumb {...excludeKeys(this.props)}>
        {this.props.children}
      </ImportBreadcrumb>
    );
  }
}

export class BreadcrumbDivider extends Component {
  render() {
    return (
      <ImportBreadcrumb.Divider {...excludeKeys(this.props)}>
        {this.props.children}
      </ImportBreadcrumb.Divider>
    );
  }
}

export class BreadcrumbSection extends Component {
  render() {
    return (
      <ImportBreadcrumb.Section {...excludeKeys(this.props)}>
        {this.props.children}
      </ImportBreadcrumb.Section>
    );
  }
}

Breadcrumb.propTypes = {
  children: PropTypes.node,         // Breadcrumb has flexible content
};

BreadcrumbDivider.propTypes = {
  children: PropTypes.node,         // BreadcrumbDivider has flexible content
};

BreadcrumbSection.propTypes = {
  children: PropTypes.node,         // BreadcrumbSection has flexible content
};
