import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List as ImportList } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';
import radium from 'radium';

const RadiumListItem = radium(ImportList.Item);

export class List extends Component {
  render() {
    return (
      <ImportList {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportList>
    );
  }
}

export class ListContent extends Component {
  render() {
    return (
      <ImportList.Content {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportList.Content>
    );
  }
}

export class ListDescription extends Component {
  render() {
    return (
      <ImportList.Description {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportList.Description>
    );
  }
}

export class ListHeader extends Component {
  render() {
    return (
      <ImportList.Header {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportList.Header>
    );
  }
}

export class ListIcon extends Component {
  render() {
    return (
      <ImportList.Icon {...excludeKeys(this.props)} />
    );
  }
}

export class ListItem extends Component {
  render() {
    return (
      <RadiumListItem {...excludeKeys(this.props)}>
        { this.props.children }
      </RadiumListItem>
    );
  }
}

export class ListList extends Component {
  render() {
    return (
      <ImportList.List {...excludeKeys(this.props)}>
        { this.props.children }
      </ImportList.List>
    );
  }
}

List.propTypes = {
  children: PropTypes.node,         // List has flexible content
};

ListContent.propTypes = {
  children: PropTypes.node,         // ListContent has flexible content
};

ListDescription.propTypes = {
  children: PropTypes.node,         // ListDescription has flexible content
};

ListHeader.propTypes = {
  children: PropTypes.node,         // ListHeader has flexible content
};

ListItem.propTypes = {
  children: PropTypes.node,         // ListItem has flexible content
};

ListList.propTypes = {
  children: PropTypes.node,         // ListList has flexible content
};

export default List;
