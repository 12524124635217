import React, { Component } from 'react';
import { Form as ImportForm } from 'semantic-ui-react';
import field from '../../../hoc/field';
import excludeKeys from '../../../../../utils/excludeKeys';

class FormInput extends Component {
  render() {
    return (
      <ImportForm.Input {...excludeKeys(this.props)} />
    );
  }
}

export default field(FormInput);
