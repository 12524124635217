import React, { Component } from 'react';
import { Dimmer as ImportDimmer } from 'semantic-ui-react';
import excludeKeys from '../../../../utils/excludeKeys';

class Dimmer extends Component {
	render() {
		return (
			<ImportDimmer {...excludeKeys(this.props)}>
				{this.props.children}
			</ImportDimmer>
		);
	}
}

export default Dimmer;
