import React, { Component } from 'react';
import { Form as ImportForm } from 'semantic-ui-react';
import excludeKeys from '../../../../../utils/excludeKeys';

class FormCheckbox extends Component {
  render() {
    return (
      <ImportForm.Checkbox {...excludeKeys(this.props)} />
    );
  }
}

export default FormCheckbox;
